import * as React from "react";
import PrimaryButtonWithArrow from "../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import styles from "./CustomerStoriesCard.module.scss";

type CustomerStoriesCardProps = {
  CustomerStoriesGridListItem: {
    customerStoriesButtonText: string;
    customerStoriesButtonUrl: string;
    customerStoriesImage: { localFile: ImageDataLike };
    customerStoriesImageAltText: string;
    customerStoriesPageName: string;
    customerStoriesTitle: string;
    customerStoriesText: string;
  };
};

const CustomerStoriesCard: React.FC<CustomerStoriesCardProps> = ({
  CustomerStoriesGridListItem: {
    customerStoriesButtonText,
    customerStoriesButtonUrl,
    customerStoriesImage,
    customerStoriesImageAltText,
    customerStoriesPageName,
    customerStoriesTitle,
    customerStoriesText,
  },
}) => {
  const image = getImage(customerStoriesImage?.localFile);

  return (
    <div className={`full-width ${styles.customerStoriesGrid}`}>
      <div className={styles.customerStoriesTextContainer}>
        <p className={styles.customerStoriesPageName}>
          {customerStoriesPageName}
        </p>
        <h2
          data-testid="h2:customerStoriesTitle"
          className={styles.customerStoriesTitle}
        >
          {customerStoriesTitle}
        </h2>

        <p className={styles.customerStoriesText}>{customerStoriesText}</p>
        <PrimaryButtonWithArrow
          buttonText={customerStoriesButtonText}
          buttonLink={customerStoriesButtonUrl}
          className={styles.Cardbutton}
        />
      </div>
      <div className={styles.customerStoriesImage}>
        <a data-testid="a:CustomerStoriesCard" href={customerStoriesButtonUrl}>
          <GatsbyImage image={image} alt={customerStoriesImageAltText || ""} />
        </a>
      </div>
    </div>
  );
};

export default CustomerStoriesCard;
