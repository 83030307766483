export const formatData = nodes => {
  if (!nodes || !nodes.length) {
    return { nodes: [], bannerInfo: {} };
  }

  const data = nodes.map(card => ({
    customerStoriesButtonText: "Read Now",
    customerStoriesButtonUrl: card.uri,
    customerStoriesImage: card.customerStoriesC.reviewImage,
    customerStoriesImageAltText: card.customerStoriesC.reviewImage.altText,
    customerStoriesPageName: "Customer Story",
    customerStoriesTitle: card.title,
    customerStoriesText: card.customerStoriesC.reviewSubcopy,
  }));

  const bannerInfo = data.pop();

  return {
    formattedNodes: data,
    bannerInfo,
  };
};
