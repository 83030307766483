import React from "react";
import CustomerStoriesCard from "../../components/CustomerStoriesCard/CustomerStoriesCard";
import styles from "./CustomerStoriesCardContainer.module.scss";

const CustomerStoriesCardContainer: React.FC<{
  CustomerStoriesGridList: any;
}> = ({ CustomerStoriesGridList }) => {
  const Cards =
    CustomerStoriesGridList.length > 0 &&
    CustomerStoriesGridList.map((stories, index) => {
      return (
        <CustomerStoriesCard
          CustomerStoriesGridListItem={stories}
          key={index}
        />
      );
    });

  return (
    <section
      data-testid="div:customer-stories-card-container"
      className={`pad-top--large ${styles.cardContainer}`}
    >
      {Cards}
    </section>
  );
};

export default CustomerStoriesCardContainer;
