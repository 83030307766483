import * as React from "react";
import CustomerStoriesBanner from "../../components/Banners/CustomerStoriesBanner/CustomerStoriesBanner";
import CustomerStoriesCardContainer from "../../containers/CustomerStoriesCardContainer/CustomerStoriesCardContainer";
import { useGetCustomerStoryData } from "./query-hook";
import { YoastSEOType } from "../../assets/Types";
import { graphql, PageProps } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
  };
};

const CustomerStoriesPage: React.FC<PageProps<DataProps>> = ({
  data: { wpPage },
  location,
}) => {
  const { formattedNodes, bannerInfo } = useGetCustomerStoryData();
  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <CustomerStoriesBanner
        heroSingleSubcopy={bannerInfo?.customerStoriesText}
        heroSingleTitle={bannerInfo?.customerStoriesTitle}
        heroCustomerPageName={"Customer Story"}
        heroInputPlaceholder={bannerInfo?.customerStoriesImage}
        heroSingleLink={bannerInfo.customerStoriesButtonUrl}
        heroSingleBadge={bannerInfo?.customerStoriesButtonText}
      />
      <CustomerStoriesCardContainer CustomerStoriesGridList={formattedNodes} />
    </LayoutWithLargeFooterCta>
  );
};

export default CustomerStoriesPage;

export const CUSTOMER_STORY_TEMPLATE_QUERY = graphql`
  query CustomerStoryTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
    }
  }
`;
