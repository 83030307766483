import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import DownArrow from "../../SVGIcons/DownArrow/DownArrow";
import { HeroSinglePProps } from "../../../assets/Types/AcfGroupTypes/HeroSingleP.type";
import PrimaryButtonWithArrow from "../../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";

import styles from "./CustomerStoriesBanner.module.scss";

const CustomerStoriesBanner: React.FC<HeroSinglePProps> = ({
  heroSingleSubcopy,
  heroSingleTitle,
  heroCustomerPageName,
  heroInputPlaceholder,
  heroSingleLink,
  heroSingleBadge,
}) => {
  const image = getImage(heroInputPlaceholder?.localFile);
  return (
    <>
      <section
        data-testid="section:customer-stories-banner"
        className={`backgroundBlue pos--relative ${styles.customerStoriesContainer}`}
      >
        <div className="full-width">
          <div className={styles.heroMainGrid}>
            <div className={styles.heroTextContainer}>
              <span>{heroCustomerPageName}</span>
              <span className="hr"></span>
              <h1>{heroSingleTitle}</h1>
              <p>{heroSingleSubcopy}</p>
              <PrimaryButtonWithArrow
                buttonText={heroSingleBadge}
                buttonLink={heroSingleLink}
                className={styles.mainGridButton}
              />
            </div>

            <div className={styles.heroImage}>
              <a data-testid="a:CustomerStoriesBanner" href={heroSingleLink}>
                <GatsbyImage image={image} alt={heroSingleTitle || ""} />
              </a>
            </div>
          </div>

          <div className={styles.moreStoriesCard}>
            <DownArrow cls={styles.icon} />
            More stories
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerStoriesBanner;
