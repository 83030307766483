import { useStaticQuery, graphql } from "gatsby";
import { formatData } from "./helpers";
import { CustomerStoriesProps } from "./types";

export const useGetCustomerStoryData = () => {
  const data: CustomerStoriesProps = useStaticQuery(graphql`
    query switchingTemplateQueryAndSwitchingTemplateQuery {
      allWpCustomerStory {
        nodes {
          uri
          title
          customerStoriesC {
            reviewWysiwyg
            reviewSubcopy
            fieldGroupName
            reviewImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 512
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
            reviewDetailList {
              detailInfo
              detailLabel
            }
          }
          contentType {
            node {
              id
              name
            }
          }
        }
      }
    }
  `);

  const {
    allWpCustomerStory: { nodes },
  } = data;

  const { formattedNodes, bannerInfo } = formatData(nodes);

  return {
    formattedNodes,
    bannerInfo,
  };
};
